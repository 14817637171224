import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
	apiKey: 'AIzaSyCw7Cb6XEe_6f5xpIsFkXZUKwAhWPsMuv0',
	version: 'weekly',
});


export default new class Map {
	initMap() {
		if (document.getElementById('map')) {
			// Define styles
			var stylers = [
				{
					"featureType": "all",
					"elementType": "labels.text",
					"stylers": [
						{
							"color": "#878787"
						}
					]
				},
				{
					"featureType": "all",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"color": "#eaf1f1"
						}
					]
				},
				{
					"featureType": "landscape.natural",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#b0e1b7"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.attraction",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.business",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.government",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.medical",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.place_of_worship",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.sports_complex",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "all",
					"stylers": [
						{
							"color": "#81b1c2"
						}
					]
				}
			];

			var markers = [];

			var image = new URL('../images/amo.png', import.meta.url);
			markers.push(['50.4332162', '4.6086209', false, '<div class="map_info"><img src="' + image.href + '"/><h3>AMO Basse-Sambre (Action en Milieu Ouvert)</h3><p><strong>L’AMO Basse-Sambre</strong> est un service agréé dans le secteur de <strong>l’aide à la jeunesse</strong>. Elle réalise de la <strong>prévention éducative et sociale</strong> au bénéfice des <b>jeunes, de leurs familles et familiers</b>. L’AMO apporte une <b>aide gratuite</b> et <b>anonyme pour tous les jeunes</b> qui lui adressent leurs questions.</p><ul><li>6, rue de la passerelle, 5060 Tamines </li><li><a href="tel:071/760.078">071/760.078</a></li><li><a href="mailto:info@amobassesambre.be">info@amobassesambre.be</a></li><li><a target="_blank" href="https://amobassesambre.be/">https://amobassesambre.be/</a></li><li>Les personnes de référence pour les jeunes : Toute l’équipe</li></ul></div>']);

			var image = new URL('../images/cracs.png', import.meta.url);
			markers.push(['50.4487617', '4.6313144', false, '<div class="map_info"><img src="' + image.href + '"/><h3>Centre culturel de Sambreville</h3><p>Le <b>CRAC’S</b> contribue à <b>rendre la culture accessible au plus grand nombre</b>, par la diffusion de <b>spectacles</b>, l’organisation de <b>stages et d’ateliers</b> et par l’organisation <b>d’événements</b> ponctuels.</p><ul><li>Quai de scène, Place de la gare, 5060 Auvelais</li><li><a href="tel:071/260.364">071/260.364</a></li><li><a href="mailto:info@cracs.eu">info@cracs.eu</a></li><li><a target="_blank" href="https://cracs.eu/">https://cracs.eu/</a></li><li>Les personnes de référence pour les jeunes : David Vincent.</li></ul></div>']);

			var image = new URL('../images/excepte-jeunes.png', import.meta.url);
			markers.push(['50.42488343533752', '4.64728571331135', false, '<div class="map_info"><img src="' + image.href + '"/><h3>Excepté Jeunes!</h3><p><strong>Excepté Jeunes!</strong> est une <strong>Organisation de Jeunesse </strong>agréée qui&nbsp;:</p><ul><li><strong>Anime, informe et forme les jeunes</strong> sur les questions liées à la <strong>citoyenneté</strong>&nbsp;via des <strong>stages </strong>et des <strong>plaines </strong>pendant les congés scolaires, via des <strong>ateliers créatifs </strong>et des animations de <strong>sensibilisation au sein des écoles</strong>.&nbsp; Il intervient dans la <strong>prévention du harcèlement </strong>et déconstruit les <strong>préjugés liés au genre ;</strong></li><li><strong>Sensibilise et accompagne les jeunes contre les assuétudes </strong>et <strong>pour la sécurité routière</strong> lors des festivals, fêtes estudiantines…</li><li>Apporte un <strong>soutien à la scolarité </strong>via une <strong>école de devoirs.</strong></li><li>Lutte <strong>contre la pauvreté</strong>&nbsp;: camps résidentiels pour les enfants et les jeunes de familles précarisées, Noël solidaire…</li><li>Offre des permanences en tant que <strong>point relais Infor Jeunes</strong> pour accueillir, écouter et informer les jeunes à propos des <strong>études</strong>, des <strong>jobs étudiants</strong>, du <strong>travail</strong>, du <strong>logement</strong>, <strong>des affaires sociales</strong>, etc.</li></ul><ul><li>59, rue haut Baty – 5060 Arsimont</li><li>071/711.935 – 0498/030.146</li><li><a href="mailto:animation@exceptejeunes.be">animation@exceptejeunes.be</a></li><li><a target="_blank" rel="noopener noreferrer" href="https://www.exceptejeunes.be/nos-axes">https://www.exceptejeunes.be/nos-axes</a></li></ul></div>']);

			var image = new URL('../images/cpi.png', import.meta.url);
			markers.push(['50.43504128077252', '4.607460111647938', false, '<div class="map_info"><img src="' + image.href + '"/><h3>Centre de planning et d’information de Tamines (CPI)</h3><p>Le <b>CPI informe, aide, sensibilise et éduque</b> en matière de <b>vie relationnelle, affective et sexuelle</b>. Il<b> accompagne</b> les personnes qui le souhaitent <b>sur ces mêmes questions</b>, y compris dans des <b>situations critiques</b>. Il organise également le <b>dépistage des infections sexuellement transmissibles, contraception, préservatifs gratuits, etc.</b></p><ul><li>16, Avenue du Président Roosevelt – 5060 Tamines</li><li><a href="tel:071/741.001">071/741.001</a></li><li><a href="mailto:tamines@planningfamilial.net">tamines@planningfamilial.net</a></li><li><a target="_blank" href="https://www.facebook.com/planningtamines/">https://www.facebook.com/planningtamines/</a></li><li>Les personnes de références pour les jeunes : Caroline Candeloro, Marie-Thérèse Carbini, Sandra Mercie.</li></ul></div>']);

			var image = new URL('../images/gabs.png', import.meta.url);
			markers.push(['50.43344083203026', '4.6088598595405115', false, '<div class="map_info"><img src="' + image.href + '"/><h3>Groupe d’animation de la Basse-Sambre</h3 ><p class="p1">Le GABS <strong>lutte contre les discriminations</strong>, les <strong>exclusions </strong>et les <strong>inégalités sociales</strong>, dans une logique <strong>d’éducation permanente </strong>et <strong>d’interculturalité</strong>. Il propose :</p><ul class="ul1"><li class="li2"><span class="s1">Des <strong>formations</strong>: assistant(e) administratif(ve), permis de conduire, job-coaching …</span></li><li class="li2"><span class="s1"><strong>L’accompagnement à la parentalité </strong>et l<strong>’accueil de la petite enfance</strong></span></li><li class="li2"><span class="s1"><strong>L’accompagnement </strong>dans les domaines du <strong>logement</strong>, de la <strong>santé,&nbsp; </strong>de <strong>l’insertion sociale</strong></span></li><li class="li3"><span class="s1">Des <strong>dynamiques citoyennes </strong>: débats, droits fondamentaux, soutien aux dynamiques citoyennes et de quartiers.</span></li></ul><ul class="ul1"><li class="li3">"La Ruche”, 4, rue de la passerelle - 5060 Tamines</li><li class="li5"><span class="s2"></span>071/784.271</li><li class="li6"><a target="_blank" rel="noopener noreferrer" href="https://gabs.be/"><span class="s2 s3">https://gabs.be/</span></a></li><li class="li6"><a href="mailto:info@gabs.be"><span class="s2 s3">info@gabs.be</span></a></li><li class="li5"><span class="s2"></span>Personne de référence pour les jeunes : Esteban, Marie-Jeanne.</li></ul></div>']);

			var image = new URL('../images/ldde.png', import.meta.url);
			markers.push(['50.43092870901215', '4.60753609815714', false, '<div class="map_info"><img src="' + image.href + '"/><h3>Ligue des droits et des devoirs de l’enfant de Tamines (LDDE)</h3 ><p class="p1">La <strong>Ligue&nbsp;des droits et devoirs de l’enfant</strong> <strong>veille au bien-être des enfants et des jeunes</strong>, en mettant en place <strong>du soutien psychologique</strong> et de <strong>l’aide scolaire. </strong>Elle promeut auprès d’eux et de leurs familles la <strong>citoyenneté</strong> et la <strong>participation</strong>. Elle travaille en partenariat afin de défendre les <strong>intérêts des jeunes&nbsp;</strong>et elle sensibilise à la notion de <strong>devoir des enfants.</strong></p><ul class="ul1"><li class="li2"><span class="s1"></span>1B, rue Victor Lagneau, 5060 Tamines</li><li class="li2"><span class="s1"></span>071/178.982</li><li class="li3"><a href="mailto:contact@ldde.be"><span class="s1 s2">contact@ldde.be</span></a></li><li class="li3"><a target="_blank" rel="noopener noreferrer" href="http://www.ldde.be/"><span class="s1 s2">http://www.ldde.be/</span></a></li>    <li class="li1"><span class="s1"></span>Personnes de références pour les jeunes&nbsp;: Immaculée Ntamagiro, Hamid Amazouz, Chantal Wauthier.</li></ul></div>']);

			var image = new URL('../images/logo-mj.png', import.meta.url);
			markers.push(['50.432201618438015', '4.611882242339478', false, '<div class="map_info"><img src="' + image.href + '"/><h3>Maison des Jeunes de Sambreville (MJ)</h3 ><p class="p1">La MJ Sambreville est un <strong>espace d’accueil libre</strong> ouvert aux jeunes du lundi à vendredi de 11h00 à 18h00 (Kicker, ping-pong, PS5). Tu peux y rencontrer d’autres jeunes et discuter, échanger avec les animateurs si tu as besoin d’aide, d’info ou d’un conseil.&nbsp;C’est aussi, <strong>un lieu où tu peux participer à divers ateliers, stages, concerts, projets</strong>, …&nbsp;</p><p class="p1">Tu peux également venir <strong>t’orienter personnellement et professionnellement</strong> au travers de formations (Accompagnement job, nouvelles technologies, sports, sécurité, arts de la scène,...).&nbsp;</p><p class="p1">Bienvenue à toutes et tous.<span class="s1 Apple-tab-span"></span></p><ul class="ul1"><li class="li3"><span class="s2"></span>1, rue du presbytère, 5060 Tamines</li><li class="li3"><span class="s2"></span>071/772.414</li><li class="li4"><a href="mailto:info@mjsambreville.be"><span class="s2 s3">info@mjsambreville.be</span></a><span class="s4 Apple-converted-space">&nbsp;</span><span class="s4 Apple-converted-space Apple-tab-span"></span></li><li class="li4"><a target="_blank" rel="noopener noreferrer" href="https://www.mjsambreville.be/"><span class="s5 s3">https://www.mjsambreville.be/</span></a></li><li class="li3"><span class="s2"></span>Personnes de références pour les jeunes&nbsp;: Toute l’équipe.</li></ul></div>']);

			var image = new URL('../images/PCS.png', import.meta.url);
			markers.push(['50.4490755064727', '4.6314026269943485', false, '<div class="map_info"><img src="' + image.href + '"/><h3>Plan de cohésion sociale de Sambreville (PCS)</h3><p class="p1">Le <strong>PCS</strong> fédère, au niveau local, les partenaires et citoyens, afin <strong>d’améliorer ou développer</strong> des <strong>services d’aide</strong> <strong>proposés aux citoyens en général et aux jeunes en particulier</strong> <strong>pour garantir un accès effectif aux droits fondamentaux&nbsp;</strong>: <strong>insertion socioprofessionnelle, logement, santé, alimentation, l’épanouissement culturel, social et familial et la participation citoyenne</strong> et ainsi<strong> </strong>contribuer à la construction d’une société solidaire et coresponsable pour le bien-être de toutes et<strong> </strong>de tous.</p><ul><li>8, place de la gare, 5060 Auvelais</li><li>071/742.828</li><li><a href="mailto:pcs@commune.sambreville.be">pcs@commune.sambreville.be</a></li><li><a target="_blank" rel="noopener noreferrer" href="https://www.sambreville.be">https://www.sambreville.be</a></li><li>Personne de référence pour les jeunes : Delphine Timmermans</li></ul></div>']);

			var markerImage = new URL('../images/marker.svg', import.meta.url);

			loader
				.load()
				.then((google) => {
					// Create map
					var map = new google.maps.Map(document.getElementById('map'), {
						zoom: 10,
						mapTypeId: google.maps.MapTypeId.ROADMAP,
						disableDefaultUI: true,
						styles: stylers,
					});

					// Create LatLngBounds object
					var bounds = new google.maps.LatLngBounds();
					var infowindow = new google.maps.InfoWindow();

					var i;
					if (markers) {
						for (i = 0; i < markers.length; i++) {
							var marker = new google.maps.Marker({
								map: map,
								position: new google.maps.LatLng(markers[i][0], markers[i][1]),
								icon: {
									url: markers[i][2] ? markers[i][2] : markerImage.href,
									scaledSize: markers[i][2] ? new google.maps.Size(80, 50) : new google.maps.Size(30, 40),
								},
							});

							// Extend the bounds to include each marker's position
							bounds.extend(marker.position);
							google.maps.event.addListener(marker, 'click', (function (marker, i) {
								return function () {
									if (markers[i][3]) {
										infowindow.setContent(markers[i][3])
										infowindow.open(map, marker);
									}
								}
							})(marker, i));

							infowindow.addListener('closeclick', () => {
								map.fitBounds(bounds)
							});
						}

						// Now fit the map to the newly inclusive bounds
						map.fitBounds(bounds);
					}
				})
				.catch(e => {
					// do something
					console.log(e);
				});
		}
	}
}


