import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;

import Map from './scripts/Map';

window.addEventListener("load", function (event) {
	// Map
	Map.initMap();

	// Smooth scrolling when clicking on a anchor link
	const anchors = document.querySelectorAll('a[href*="#"]:not([href="#"]');
	anchors.forEach(function (el) {
		el.addEventListener('click', function (e) {
			if (!document.querySelector(this.hash)) {
				return;
			}
			e.preventDefault();


			const target = this.hash;

			// Calcul scrollTop
			const offsetTop = document.querySelector(target).offsetTop;
			scroll({
				top: offsetTop - 120,
				behavior: 'smooth',
			});
		});
	});
});
